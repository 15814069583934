import React from 'react';
import PropTypes from 'prop-types';

import { CommonContext } from '../../context';
import SEO from '../../components/seo';
import Header from '../../components/header';

export default function EmbedPage({ location, slite_link }) {
  return (
    <CommonContext.Provider value={{ location: location }}>
      <SEO title={'Doc'} />
      <Header />
      <iframe
        seamless="seamless"
        style={{
          maxHeight: 'none!important',
        }}
        width="100%"
        type="text/html"
        src={`https://gtaf.slite.page/p/${slite_link}`}
        className="w-full h-screen"
      />
    </CommonContext.Provider>
  );
}

EmbedPage.propTypes = {
  slite_link: PropTypes.string,
  location: PropTypes.object,
};
